import React, { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import MoralisDappContext from "./context";

import contract from "../../contracts/Panda.json";

const NFT_CONTRACT_ADDRESS = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;
const abi = contract.abi;


function MoralisDappProvider({children}) {
    const {
        provider,
        Moralis,
        user
    } = useMoralis();
    const [walletAddress, setWalletAddress] = useState();
    const [chainId, setChainId] = useState();
    const [contractABI, setContractABI] = useState(abi); //Smart Contract ABI here
    const [marketAddress, setMarketAddress] = useState(NFT_CONTRACT_ADDRESS); //Smart Contract Address Here


    useEffect(() => {
        Moralis.onChainChanged(function (chain) {
            setChainId(chain);
        });

        Moralis.onAccountChanged(function (address) {
            setWalletAddress(address[0]);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setChainId(provider?.chainId);
    });
    useEffect(() => {
        setWalletAddress(provider?.selectedAddress || user?.get("ethAddress"));
        console.log(provider?.selectedAddress || user?.get("ethAddress"));
    }, [provider, user]);

    return (<MoralisDappContext.Provider value={{
        walletAddress,
        chainId,
        marketAddress,
        setMarketAddress,
        contractABI,
        setContractABI
    }}>
        {children}
    </MoralisDappContext.Provider>);
}

function useMoralisDapp() {
    const context = React.useContext(MoralisDappContext);
    if (context === undefined) {
        throw new Error("useMoralisDapp must be used within a MoralisDappProvider");
    }
    return context;
}

export { MoralisDappProvider, useMoralisDapp };
