import React from "react";
import "../css/roadmap.css";

function RoadMapModal4({ modalMapClose4 }) {
  return (
    <div className="dimensioned_page_modal4">
      <div className="modal_page_layer4">
        <div className="modal_dimesioned_container4">
          <div className="modal_info4">
            <div className="modal_title4">
              <h2>Step 4</h2>
              <h3>Physical Products Launch / RPC Events</h3>
            </div>
            <div className="modal_icons4">
              <button
                className="close_modal4"
                onClick={() => modalMapClose4(false)}
              >
                <span className="material-symbols-sharp">close</span>
              </button>
            </div>
          </div>
          <div className="modal_map_body4">
            <ul>
              <li>- Physical product release</li>
              <li>- Biodegradable packaging</li>
              <li>- RPC events</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoadMapModal4;
