import React from "react";
import "../css/roadmap.css";

function RoadMapModal5({ modalMapClose5 }) {
  return (
    <div className="dimensioned_page_modal5">
      <div className="modal_page_layer5">
        <div className="modal_dimesioned_container5">
          <div className="modal_info5">
            <div className="modal_title5">
              <h2>Step 5</h2>
              <h3>Launch Phase 1.3</h3>
            </div>
            <div className="modal_icons5">
              <button
                className="close_modal5"
                onClick={() => modalMapClose5(false)}
              >
                <span className="material-symbols-sharp">close</span>
              </button>
            </div>
          </div>
          <div className="modal_map_body5">
            <ul>
              <li>
                - Launch 150 Special Pandas mint on OpenSea (ad-hoc monthly
                release aligned to special events)
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoadMapModal5;
