import React from "react";
import "../css/home.css";/* 
import gif from "../assets/BackgroundGifCinema.gif"; */

function Home({isServerInfo}) {
    return (
        <div className="home_page">
          <div className="panda_layer">{/* 
            <div className="home_gif" style={{backgroundImage: "url("+gif+")"}}>
            </div> */}
          </div>
        </div>
      );
}

export default Home;
