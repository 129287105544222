import React, { useRef } from "react";
import emailjs from '@emailjs/browser';
import "../css/contacts.css";
import logo from "../assets/logo.png";

function Contacts() {
  
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_dkpf2u5', 'template_5yxjdaa', form.current, 'N7B56LAsPs1TqNAX7')
      .then((result) => {
          console.log(result.text);
          window.location.reload();
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
     <div className="contacts_page">
      <div className="contenitore_contatti">
        <h5>Contact Us</h5>
        <form className="contatti_form" ref={form} onSubmit={sendEmail}>
          <label className="label">First Name</label>
          <input type="text" name="nome" className="input_contatti" />
          <label className="label">Last Name</label>
          <input type="text" name="cognome" className="input_contatti" />
          <label className="label">Email</label>
          <input type="email" name="email" className="input_contatti" />
          <label className="label">Object</label>
          <input type="text" name="oggetto" className="input_contatti" />
          <label className="label">Message</label>
          <textarea name="messaggio" className="input_contatti"></textarea>
          <input type="submit" className="submit_btn" value="Send" />
        </form>
      </div>
      <div className="contatti">
        <img src={logo} alt="logo" className="big_logo" />
        <h5>Retro Panda Club</h5>
        <h6>© copyright 2022</h6>
        <span>Email</span>
        <a href="mailto:info@retropanda.club">info@retropanda.club</a>
      </div>
    </div>
  );
}

export default Contacts;
