import React from "react";
import "../css/about.css";
import mei_sheng from "../assets/Mei Sheng.png";
import tao_tao from "../assets/Tao Tao.png";
import shi_shi from "../assets/shi_shi.png";
import pan_pan from "../assets/pan_pan.png";
import mei_xiang from "../assets/mei_xiang.png";

function Team() {
  return (
    <div className="about_page">
      <div className="main_container">
        <div className="un_membro">
            <div className="avatar">
              <img title="avatar" src={mei_sheng} />
            </div>
          <div className="description">
            <h1>Mei Sheng</h1>
            <h2>
              Code Developer
            </h2>
              <p>
              Mei Sheng, the professor, is the Head of the Research Center of RPC. 
              His six fingers help him shoot bugs on his computer. 
              Crazy haired with a nerdy attitude, he holds a high school diploma 
              in computer science and AI from Hogwarts School of Witchcraft and Wizardry.
              Incanto Patronus!!
            </p>
          </div>
        </div>
      <div className="un_membro">
          <div className="avatar">
            <img title="avatar" src={tao_tao} />
          </div>
        <div className="description">
          <h1>Tao Tao</h1>
          <h2>
            Project Manager
          </h2>
          <p>
            Don't worry about extinction, as Tao Tao is the caretaker of RPC. 
            Tao Tao's passion is to bring our virtual furry friends to real life. 
            The guy is a poet and amuses the team with oratorical speeches. 
            Tao Tao is also the master behind RPC, always scouting for the brightest minds.
          </p>
        </div>
      </div>
      <div className="un_membro">
          <div className="avatar">
            <img title="avatar" src={shi_shi} />
          </div>
        <div className="description">
          <h1>Shi Shi</h1>
          <h2>
            Smart Contract & Web3
          </h2>
          <p>
            Pandas like to connect with trees by giving them passionate hugs, 
            Shi Shi likes to connect worlds with numbers. Constantly surfing the blockchain, 
            checking the best combinations to keep the workplace safe. 
            He is tech-savvy and a grass eater (bamboo works too). 
          </p>
        </div>
      </div>
      <div className="un_membro">
          <div className="avatar">
          <img title="avatar" src={mei_xiang} />
          </div>
        <div className="description">
          <h1>Pan Pan</h1>
          <h2>
            NFT Business Developer, Marketing and PR
          </h2>
          <p>
            Pan Pan always wears giant black sunglasses to cover the dark circles under his eyes 
            caused by late-night work/crazy parties. Panda loves eating 14 hours a day… 
            our marketing specialist does the same! One of a kind NFT enthusiast who spent the last 
            69420 years of his life learning from the best players in the space. 
            He says that his endless wild brain conceived the development strategy and comes up with 
            mind-blowing ideas about marketing and communication. It doesn't matter, WGMI anyway!!
          </p>
        </div>
      </div>
      <div className="un_membro">
        <div className="avatar">
            <img title="avatar" src={pan_pan} />
        </div>
        <div className="description">
          <h1>Mei Xiang</h1>
          <h2>
            Social Media Manager
          </h2>
          <p>
            Tango, horses, and Messi. 
            Any guesses? She works in Public and Institutional Relations. 
            Travelling around different galaxies, Mei Xiang loves connecting with aliens and lizard people. 
            A true genius in marketing digital communication, a BlackBerry 7290 is her favourite tool to 
            scroll around social platforms (old habits die hard).
          </p>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Team;
