import React from "react";
// import ReactDOM from "react-dom";
import { MoralisProvider } from "react-moralis";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { MoralisDappProvider } from "./providers/MoralisDappProvider/MoralisDappProvider";
// import reportWebVitals from './reportWebVitals';
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";


const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;


const Application = () => {
    const isServerInfo = !!(APP_ID && SERVER_URL);
    if (isServerInfo) {
        return (<MoralisProvider
            appId={APP_ID}
            serverUrl={SERVER_URL}
        >
            <MoralisDappProvider>
                <App isServerInfo/>
            </MoralisDappProvider>
        </MoralisProvider>);
    } else {
        return (<div style={{
            display: "flex",
            justifyContent: "center"
        }}>
            <div></div>
            {/*<QuickStart/>*/}
        </div>);
    }
};


// ReactDOM.render(
//     // <React.StrictMode>
//     <Application/>, // </React.StrictMode>,
//     document.getElementById("root")
// );


const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(<App tab="home" />);
root.render(<Application/>);


// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();
