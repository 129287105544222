import React, { useState } from "react";
import { useMoralisDapp } from "../providers/MoralisDappProvider/MoralisDappProvider";

import "../css/mint.css";

// require("dotenv").config();

const HDWalletProvider = require("@truffle/hdwallet-provider");
const web3 = require("web3");
const MNEMONIC = process.env.REACT_APP_MNEMONIC;
const NODE_API_KEY = process.env.REACT_APP_INFURA_KEY || process.env.REACT_APP_ALCHEMY_KEY;
const isInfura = !!process.env.REACT_APP_INFURA_KEY;
const FACTORY_CONTRACT_ADDRESS = process.env.REACT_APP_FACTORY_CONTRACT_ADDRESS;
const NFT_CONTRACT_ADDRESS = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;
const OWNER_ADDRESS = process.env.REACT_APP_OWNER_ADDRESS;
const NETWORK = process.env.REACT_APP_NETWORK;
const NUM_LOOTBOXES = 0;
const DEFAULT_OPTION_ID = 0;
const LOOTBOX_OPTION_ID = 2;
//
// if (!MNEMONIC || !NODE_API_KEY || !OWNER_ADDRESS || !NETWORK) {
//     console.error("Please set a mnemonic, Alchemy/Infura key, owner, network, and contract address.");
//     return;
// }


const NFT_ABI = [
    {
        constant: false,
        inputs: [
            {
                name: "_to",
                type: "address"
            }
        ],
        name: "mintTo",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function"
    }
];

const FACTORY_ABI = [
    {
        constant: false,
        inputs: [
            {
                name: "_optionId",
                type: "uint256"
            }, {
                name: "_toAddress",
                type: "address"
            }
        ],
        name: "mint",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function"
    }
];


function Factory() {

    const {
        chainId,
        walletAddress
    } = useMoralisDapp();
    const [minting, setMinting] = useState(false);
    const [numCreatures, setNumCreatures] = useState(0);


    const mint = async () => {

        if (!MNEMONIC || !NODE_API_KEY || !OWNER_ADDRESS || !NETWORK) {
            console.error("Please set a mnemonic, Alchemy/Infura key, owner, network, and contract address.");
            return;
        }

        let provider;
        if (NETWORK === "development") {
            provider = new HDWalletProvider(MNEMONIC, "http://127.0.0.1:7545");
        } else {
            const network = NETWORK === "mainnet" || NETWORK === "live"
                ? "mainnet"
                : "rinkeby";
            provider = new HDWalletProvider(
                MNEMONIC, isInfura
                    ? "https://" + network + ".infura.io/v3/" + NODE_API_KEY
                    : "https://eth-" + network + ".alchemyapi.io/v2/" + NODE_API_KEY);
        }
        const web3Instance = new web3(provider);

        let i;

        if (FACTORY_CONTRACT_ADDRESS) {

            setMinting(true);

            const factoryContract = new web3Instance.eth.Contract(
                FACTORY_ABI, FACTORY_CONTRACT_ADDRESS, {gasLimit: "1000000"});

            // Creatures issued directly to the owner.
            for (i = 0; i < numCreatures; i++) {
                const result = await factoryContract.methods
                                                    .mint(DEFAULT_OPTION_ID, OWNER_ADDRESS)
                                                    .send({from: OWNER_ADDRESS});
                console.log("Minted panda. Transaction: " + result.transactionHash);
            }

            // Lootboxes issued directly to the owner.
            for (i = 0; i < NUM_LOOTBOXES; i++) {
                const result = await factoryContract.methods
                                                    .mint(LOOTBOX_OPTION_ID, OWNER_ADDRESS)
                                                    .send({from: OWNER_ADDRESS});
                console.log("Minted lootbox. Transaction: " + result.transactionHash);
            }

        } else if (NFT_CONTRACT_ADDRESS) {

            setMinting(true);

            const nftContract = new web3Instance.eth.Contract(NFT_ABI, NFT_CONTRACT_ADDRESS, {gasLimit: "1000000"});

            // Creatures issued directly to the owner.
            for (i = 0; i < numCreatures; i++) {
                const result = await nftContract.methods
                                                .mintTo(walletAddress)
                                                .send({from: walletAddress});
                console.log("Minted panda. Transaction: " + result.transactionHash);
            }

        } else {

            console.error("Add NFT_CONTRACT_ADDRESS or FACTORY_CONTRACT_ADDRESS to the environment variables");

        }

    };


    return (
        <div className="mint_page">
          <div className="mint_continer">
            <h5>How many Pandas?</h5>
            <form className="mint_form">
              <input type="number" className="mint_input" min="0"></input>
              <button className="mint_submit" onClick={() => mint()}>Mint!</button>
            </form>
          </div>
        </div>
    );


}


export default Factory;
