import React, { useEffect, useState } from "react";
import { useMoralisQuery } from "react-moralis";
import { Image, Modal, Space, Table, Typography } from "antd";


const {Title} = Typography;


const SUMMARY = require("../metadata/summary.json");


const imgWidth = 70;
const maxExamples = 10;


// {
//   title: 'Tags',
//   key: 'tags',
//   dataIndex: 'tags',
//   render: tags => (
//     <span>
//       {tags.map(tag => {
//         let color = tag.length > 5 ? 'geekblue' : 'green';
//         if (tag === 'loser') {
//           color = 'volcano';
//         }
//         return (
//           <Tag color={color} key={tag}>
//             {tag.toUpperCase()}
//           </Tag>
//         );
//       })}
//     </span>
//   ),
// },
// {
//   title: 'Action',
//   key: 'action',
//   render: (text, record) => (
//     <Space size='middle'>
//       <a>Invite {record.name}</a>
//       <a>Delete</a>
//     </Space>
//   ),
// },


function TableList() {


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [parsedData, setParsedData] = useState(null);


    // const getMetadata = () => {
    //     let exists = true;
    //     let i = 0;
    //     while (exists && i < 50000) {
    //         i++;
    //         // exists = false;
    //         console.log(i);
    //         const url = METADATA_URL + i.toString();
    //         console.log(url);
    //         // const response = await fetch(url);
    //         // const data = await response.json();
    //         const data = fetch(url)
    //             .then(response => {
    //                 const isJson = response.headers.get("content-type")?.includes("application/json");
    //                 if (isJson) {
    //                     return response.json();
    //                 } else {
    //                     return null;
    //                 }
    //                 // check for error response
    //                 // if (!response.ok) {
    //                 //     // get error message from body or default to response status
    //                 //     const error = (data && data.message) || response.status;
    //                 //     return Promise.reject(error);
    //                 // }
    //                 // setImagesData(data);
    //             })
    //             .then(result => setImagesData(result))
    //             .catch(error => {
    //                 exists = false;
    //                 console.error("There was an error!", error);
    //             });
    //         console.log(data);
    //     }
    // };


    const showModal = () => {
        setIsModalVisible(true);
    };


    const handleOk = () => {
        setIsModalVisible(false);
    };


    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const columns = [
        {
            title: "Attribute",
            dataIndex: "attribute",
            key: "attribute",
            render: text => <a>{text}</a>
        }, {
            title: "#",
            dataIndex: "n",
            key: "n"
        }, {
            title: "Avail",
            dataIndex: "available",
            key: "available"
        }, {
            title: "Avg Sale",
            dataIndex: "avgSale",
            key: "avgSale"
        }, {
            title: "Cheapest",
            dataIndex: "cheapest",
            key: "cheapest"
        }, {
            title: "More Examples",
            dataIndex: "list",
            key: "list",
            render: list => (<span>
          {list
              ? list.slice(0, Math.min(list.length, maxExamples))
                    .map(data => {
                        return (<Image
                                width={imgWidth}
                                src={data.image}
                                preview={false}
                                onClick={() => showModal()}
                                key={data.image}
                            />
                            //                         <Card
                            //   hoverable
                            //   style={{ width: {imgWidth} }}
                            //   cover={<img alt="example" src={data.image} style={{ width: {imgWidth} }} />}
                            // >
                            //
                            // </Card>
                        );
                    })
              : <div></div>}
        </span>)
        }
    ];


    const parseTypes = () => {
        const finalData = [];
        for (const [key, dict] of Object.entries(SUMMARY)) {
            const data = [];
            for (const [attribute, list] of Object.entries(dict)) {
                data.push({
                    "attribute": attribute,
                    "n": list.length,
                    "list": list
                });
            }
            finalData.push({
                "title": key,
                "data": data
            });
        }
        console.log(finalData);
        setParsedData(finalData);
    };


    useEffect(() => {
        parseTypes();
    }, []);


    return (<div>
        <Space direction="vertical" size="middle" style={{display: "flex"}}>
            {parsedData
                ? parsedData.map(({
                                      title,
                                      data
                                  }) => {
                    return <div key={title}>
                        <Title level={2}>{title}</Title>
                        <Table
                            columns={columns}
                            dataSource={data}
                            pagination={false}
                            rowKey="Id"
                        />

                    </div>;
                })
                : <div></div>}
        </Space>
        <Modal
            title="Basic Modal"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}>
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
        </Modal>
    </div>);
}


export default TableList;
