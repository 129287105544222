import { React, useState } from "react";
import "../css/menu.css";
import logo from "../assets/logo.png";
import { NavLink } from "react-router-dom";
import WhitePaperModal from "../modals/WhitePaperModal";
import linktr from "../assets/linktree.png";
import instagram from "../assets/Instagram.png";
import tiktok from "../assets/tiktok.png";
import twitter from "../assets/twitter.png";
import discord from "../assets/discord.png";

function MainMenu() {
  const [statoModal, setStatoModal] = useState(false);
  const [statoMenu, setStatoMenu] = useState(false);

  return (
    <header className="App-header">
      <div className="social_links">
        <a href="https://linktr.ee/RetroPandaClub" target="_blank">
          <img src={linktr} />
        </a>
        <a href="https://www.instagram.com/retropandaclub/" target="_blank">
          <img src={instagram} />
        </a>
        <a href="http://www.tiktok.com/@retropandaclub" target="_blank">
          <img src={tiktok} />
        </a>
        <a href="https://twitter.com/RetroPandaClub" target="_blank">
          <img src={twitter} />
        </a>
        <a href="https://discord.gg/GD2En78C5P" target="_blank">
          <img src={discord} />
        </a>
        <button className="logo" onClick={() => setStatoModal(true)}>
          <img src={logo} />
        </button>
      </div>
      <div className="menu_btn">
        <span
          class="material-symbols-sharp"
          style={{ marginLeft: statoMenu ? "-42px" : "0" }}
          onClick={() => setStatoMenu(true)}
        >
          menu
        </span>
        <span
          class="material-symbols-sharp"
          onClick={() => setStatoMenu(false)}
        >
          close
        </span>
      </div>
      <div className="main_menu" style={{ bottom: statoMenu ? "0" : "100%" }}>
        <NavLink
          className="voce_menu"
          to="/home"
          onClick={() => setStatoMenu(false)}
          activeClassName="active"
        >
          home
        </NavLink>
        {/* <NavLink className="voce_menu" to="/factory" onClick={()=>setStatoMenu(false)} activeClassName="active">mint</NavLink> */}
        <NavLink
          className="voce_menu"
          to="/roadmap"
          onClick={() => setStatoMenu(false)}
          activeClassName="active"
        >
          roadmap
        </NavLink>
        <NavLink
          className="voce_menu"
          to="/team"
          onClick={() => setStatoMenu(false)}
          activeClassName="active"
        >
          team
        </NavLink>
        <NavLink
          className="voce_menu"
          to="/contacts"
          onClick={() => setStatoMenu(false)}
          activeClassName="active"
        >
          contacts
        </NavLink>
      </div>
      {statoModal && <WhitePaperModal closeModal={setStatoModal} />}
    </header>
  );
}

export default MainMenu;
