import React, { useEffect } from "react";
import { useMoralis } from "react-moralis";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Layout } from "antd";
import "./css/App.css";
//import "./index.css";
import Home from "./components/Home";
import TableList from "./components/ListAttributes";
import Team from "./components/Team";
import MainMenu from "./components/MainMenu";
import Factory from "./components/Factory";
import RoadMap from "./components/RoadMap";
import Contacts from "./components/Contacts";
import WhitePaperModal from "./modals/WhitePaperModal";

function App({ isServerInfo }) {
  const { isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading } =
    useMoralis();

  useEffect(() => {
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) {
      enableWeb3();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);

  return (
    <Router forceRefresh={true}>
      <MainMenu />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/contacts" element={<Contacts />} />
        <Route exact path="/team" element={<Team />} />
        <Route exact path="/roadmap" element={<RoadMap />} />
        <Route exact path="/factory" element={<Factory />} />
      </Routes>
    </Router>
  );
}

export default App;
