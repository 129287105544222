import React from "react";
import "../css/mapModal.css";

function RoadMapModal2({ modalMapClose2 }) {
  return (
    <div className="dimensioned_page_modal2">
      <div className="modal_page_layer2">
        <div className="modal_dimesioned_container2">
          <div className="modal_info2">
            <div className="modal_title2">
              <h2>Step 2</h2>
              <h3>Scouting NGO<b>s</b> and rural communities worldwide</h3>
            </div>
            <div className="modal_icons2">
              <button
                className="close_modal2"
                onClick={() => modalMapClose2(false)}
              >
                <span className="material-symbols-sharp close_icon">close</span>
              </button>
            </div>
          </div>
          <div className="modal_map_body2">
            <ul class>
              <li>- Networking</li>
              <li>- Public goods commitment</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoadMapModal2;
