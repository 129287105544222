import { React, useState } from "react";
import "../css/roadmap.css";
import RoadMapModal from "../modals/RoadMapModal";
import RoadMapModal2 from "../modals/RoadMapModal2";
import RoadMapModal3 from "../modals/RoadMapModal3";
import RoadMapModal4 from "../modals/RoadMapModal4";
import RoadMapModal5 from "../modals/RoadMapModal5";

function RoadMap() {
  const [modalMapClose, setModalMapClose] = useState(false);
  const [modalMapClose2, setModalMapClose2] = useState(false);
  const [modalMapClose3, setModalMapClose3] = useState(false);
  const [modalMapClose4, setModalMapClose4] = useState(false);
  const [modalMapClose5, setModalMapClose5] = useState(false);
  return (
    <div className="bg_layer">
      <div className="clouds">
        <div className="clouds_layer">
          <div className="cloud cloud2"></div>
          <div className="cloud cloud4"></div>
          <div className="cloud cloud3"></div>
          <div className="cloud cloud5"></div>
          <div className="cloud cloud1"></div>
        </div>
      </div>
      <div className="roadmap_page">
        <div className="phases">
          <div className="phase">PHASE 1</div>
        </div>
        <div className="contenitore_mele">
          <div className="mela mela1" onClick={() => setModalMapClose4(true)}>4</div>
          <div className="mela mela2" onClick={() => setModalMapClose2(true)}>2</div>
          <div className="mela mela3" onClick={() => setModalMapClose5(true)}>5</div>
          <div className="mela mela4" onClick={() => setModalMapClose3(true)}>3</div>
          <div className="mela mela5" onClick={() => setModalMapClose(true)}>1</div>
        </div>
      </div>
      {modalMapClose && <RoadMapModal modalMapClose={setModalMapClose} />}
      {modalMapClose2 && <RoadMapModal2 modalMapClose2={setModalMapClose2} />}
      {modalMapClose3 && <RoadMapModal3 modalMapClose3={setModalMapClose3} />}
      {modalMapClose4 && <RoadMapModal4 modalMapClose4={setModalMapClose4} />}
      {modalMapClose5 && <RoadMapModal5 modalMapClose5={setModalMapClose5} />}
    </div>
  );
}

export default RoadMap;
