import React from "react";
import "../css/mapModal.css";

function RoadMapModal3({ modalMapClose3 }) {
  return (
    <div className="dimensioned_page_modal3">
      <div className="modal_page_layer3">
        <div className="modal_dimesioned_container3">
          <div className="modal_info3">
            <div className="modal_title3">
              <h2>Step 3</h2>
              <h3>NFT Launch Phase 1.2</h3>
            </div>
            <div className="modal_icons3">
              <button
                className="close_modal3"
                onClick={() => modalMapClose3(false)}
              >
                <span className="material-symbols-sharp">close</span>
              </button>
            </div>
          </div>
          <div className="modal_map_body3">
            <ul>
              <li>
                - Launch 850 Uncommon Pandas mint on OpenSea (monthly release by
                chunks of 140/month)
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoadMapModal3;
