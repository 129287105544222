import React from "react";
import "../css/mapModal.css";

function RoadMapModal({ modalMapClose }) {
  return (
    <div className="dimensioned_page_modal">
      <div className="modal_page_layer">
        <div className="modal_dimesioned_container">
          <div className="modal_info">
            <div className="modal_title">
              <h2>Step 1</h2>
              <h3>NFT Launch Phase 1.1</h3>
            </div>
            <div className="modal_icons">
              <button
                className="close_modal"
                onClick={() => modalMapClose(false)}
              >
                <span className="material-symbols-sharp">close</span>
              </button>
            </div>
          </div>
          <div className="modal_map_body">
            <ul>
              <li>- Deploy community</li>
              <li>- Launch 4000 Basic Pandas mint on OpenSea</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoadMapModal;
