import React from "react";
import { Link } from "react-router-dom";
import "../css/whitepaper.css";
// Import the main component
import { Viewer } from "@react-pdf-viewer/core"; // install this library
// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"; // install this library
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// Worker
import { Worker } from "@react-pdf-viewer/core"; // install this library

import demoFile from "./White Paper - Retro Panda Club.pdf";
/* import logo from "../assets/logo.png";
import {Link} from "react-router-dom" */

function WhitePaperModal({closeModal}) {

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <div className="full_page_modal">
      <div className="modal_container">
        <div className="modal_fixed">
          <div className="modal_title">Retro Panda Club - White Paper</div>
          <div className="modal_icons">
            <Link
              to="/files/myfile.pdf"
              target="_blank"
              className="download_btn"
              download
            >
              <span class="material-symbols-sharp">file_download</span>
            </Link>
            <button className="close_wp_modal" onClick={()=>closeModal(false)}>
              <span class="material-symbols-sharp">close</span>
            </button>
          </div>
        </div>
        <div className="modal_body">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <Viewer fileUrl={demoFile} />
          </Worker>
        </div>
      </div>
    </div>
  );
}

export default WhitePaperModal;
